import './App.css';
import TrueShuffle from './mainPage/trueShuffle.js'

function App() {
  return (
    <TrueShuffle />
  );
}

export default App;
